import './App.css';

function App() {
	return (
		<div className="App">
			<div className="log">
				<span id="xPos"></span> / <span id="yPos"></span>
				<span id="random1"></span> / <span id="random2"></span> / <span id="random3"></span>
			</div>

			<div className="global-container">
				<div className="scene intro">
					<div className="stars"></div>
					<div className="stars stars-2"></div>
					<div className="logo-container flex flex-center">
						<div className="logo">
							<div className="text">
								<div className="line line-1">Neonclipse</div>
								<div className="line line-2">Code&nbsp;</div>
								<div className="line line-3">Workshop</div>
							</div>
						</div>
					</div>
				</div>
				<div class="scene sunset flex">
					<div class="introduction">
						<div class="name">Hi! I'm Mario</div>
						<div class="text animatable animated">
							I'm a senior backend developer based in Novi Sad, Serbia.
							<br />
							<br />
							<br /> Some of my personal hobby projects:
							<br />
							<br />
							<div class="list vertical">
								<ul>
									<li>
										<a
											href="https://github.com/coltwillcox/linuxwave"
											title=".dotfiles for Wayland/Hyprland desktop"
											target="_blank"
											rel="noreferrer">
											linuxwave
										</a>
									</li>
									<br />
									<li>
										<a
											href="https://github.com/coltwillcox/ngn"
											title="Neon Gopher Notifications"
											target="_blank"
											rel="noreferrer">
											ngn
										</a>
									</li>
									<br />
									<li>
										<a href="https://github.com/coltwillcox/ncd" title="Neon Change Directory" target="_blank" rel="noreferrer">
											ncd
										</a>
									</li>
									<br />
									<li>
										<a
											href="https://github.com/coltwillcox/synthwave-x-fluoromachine-contrast"
											title="Synthwave x Fluoromachine & epic animations & contrast"
											target="_blank"
											rel="noreferrer">
											Synthwave Fluoromachine
										</a>
									</li>
									<br />
									<li>
										<a href="https://github.com/coltwillcox/inhibitor" title="inhibitor" target="_blank" rel="noreferrer">
											inhibitor
										</a>
									</li>
								</ul>
							</div>
							<br />
							<br />
							<br />I like coding and synthwave. :)
							<br />
							<br />
							<br />
							Find me on:
						</div>
						<div class="list horizontal">
							<ul>
								<li>
									<a href="https://www.linkedin.com/in/koltinjo/" title="Linkedin" target="_blank" rel="noreferrer">
										linkedin
									</a>
								</li>
								<li>
									<a href="https://github.com/coltwillcox/" title="Github" target="_blank" rel="noreferrer">
										github
									</a>
								</li>
								<li>
									<a href="https://www.facebook.com/vukodlak" title="Codepen" target="_blank" rel="noreferrer">
										facebook
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div class="sun-container">
						<div class="sun"></div>
						<div class="palm big"></div>
						<div class="palm little"></div>
					</div>
				</div>
			</div>

			<div className="noise-white screen-fixed"></div>
			<div className="noise-black screen-fixed"></div>
			<div className="screen-pixels screen-fixed"></div>
		</div>
	);
}

export default App;
